<script setup lang="ts">
const companySlug = useRoute().params.id;
useCanonicalUrl();

const { data: companies } = await useFetch(`/api/companies/`, {
  query: {
    clientId: companySlug,
    domain:
      useIsCustomDomain() && !companySlug
        ? useRequestURL().hostname
        : undefined,
    limit: 1,
  },
});

const company = computed(() => companies.value?.at(0));

if (!company || !company.value) throw error404("Company not found");

const metaTitle = ref(`${company.value.name} | Hire Truckers`);
const metaDescription = ref(`Find trucking jobs at ${company.value.name}}`);
useSeoMeta({
  title: metaTitle,
  ogTitle: metaTitle,
  description: metaDescription,
  ogDescription: metaDescription,
});

const unbranded = useUnbranded();
const { setPrimaryColor, primaryColor } = usePrimaryColor();
setPrimaryColor(company.value?.primaryColor);
</script>

<template>
  <template v-if="company">
    <!-- Start -->
    <section
      class="relative"
      :class="{
        'py-4 md:py-8': unbranded,
        'py-16 md:py-24': !unbranded,
      }"
    >
      <div class="container pb-24">
        <div class="justify-start p-4 bg-gray-100 rounded hero">
          <h1 class="flex items-center justify-center">
            <AppCompanyLogo
              v-if="company.logo"
              :src="company.logo"
              :alt="company.name || ''"
              class="mr-5"
            />

            <div>
              <strong class="text-xl md:text-3xl">{{ company.name }}</strong>
              <small class="inline-block ml-2 text-sm opacity-75 md:text-lg"
                >Driving Jobs
              </small>
            </div>
          </h1>
        </div>
        <div
          class="w-full h-2"
          :style="{
            backgroundColor: primaryColor,
          }"
        ></div>

        <JobSearchWrapper
          :client-id="company.id"
          :omit="['company']"
        ></JobSearchWrapper>
      </div>
    </section>
  </template>
</template>
